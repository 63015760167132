export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

export const SEND_OTP_ERROR = "SEND_OTP_ERROR";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP = "SEND_OTP";

export const CHECK_OTP = "CHECK_OTP";
export const CHECK_OTP_SUCCESS = "CHECK_OTP_SUCCESS";
export const CHECK_OTP_ERROR = "CHECK_OTP_ERROR";
