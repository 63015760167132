const ArticleCustomEditor = (
  textColor,
  backgroundColor,
  text,
  textAlign,
  cssImage,
  cssText,
  img,
  caption,
  justify,
  type,
  img1,
  img2,
  captionMultiImg,
  csswidthMultiImage
) => {
  const textEditor = (text, textAlign) => {
    return (
      text &&
      text != "" &&
      `<div style="margin-bottom: 20px;width:${cssText};"><div class="text-width-web *:my-[1rem]">${text}</div></div>`
    );
  };

  const imageEditor = (img, caption) => {
    return img && img !== ""
      ? `<figure id="add_image_link" style="width:${cssImage};margin-bottom: 20px;"><img src=${img} width="100%">
    <figcaption><span style="color: ${textColor};text-align:center">${caption}</span></figcaption>
    </figure>`
      : "";
  };

  const multyImageEditor = (img1, img2, caption, csswidthMultiImage) => {
    return !img1 && !img2
      ? ""
      : `<div className="multyImage">
      <div style=" display: flex; justify-content: space-between; width:${csswidthMultiImage}; margin:0 auto;">
      <figure
          className="image"
          id="add_image_link"
          style=" width: 49.9%; margin-bottom: 20px; "
        >
          <img width=100% src=${img1} alt="" />
        </figure>
              <figure
          className="image"
          id="add_image_link"
          style="width: 49.9%; margin-bottom: 20px;"
        >
          <img width=100% src=${img2} alt="" />
        </figure>
      </div><figcaption style="text-align: center;font-style: italic;margin-top: -14px;margin-bottom: 20px;"}>${caption}</figcaption>
      </div>`;
  };

  const styleBlock = (content, justify) => {
    return `<div style="display:flex;flex-direction:row;justify-content:${justify}">${content}</div>`;
  };
  return `<div style="background-color:${backgroundColor}">${styleBlock(
    type
      ? textEditor(text, textAlign) + imageEditor(img, caption)
      : imageEditor(img, caption) + textEditor(text, textAlign),
    justify
  )} ${multyImageEditor(
    img1,
    img2,
    captionMultiImg,
    csswidthMultiImage
  )}</div>`;
};

export default ArticleCustomEditor;
