import { Label } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";

const ContentEditor = ({ value, editorRef, title, isPreview }) => {
  useEffect(() => {
    if (editorRef.current && isPreview > 0) {
      editorRef.current.execCommand("mcePreview");
    }
  }, [isPreview]);
  const [isFirst, setFirst] = useState(true);
  return (
    <div className="mb-3">
      <Label htmlFor="article_content" className="form-label">
        {title}
      </Label>
      <Editor
        onClick={() => {
          if (isFirst || editorRef.current.getContent() === "") {
            setFirst(false);
            editorRef.current.formatter.register("customStyle", {
              inline: "span",
              styles: {
                fontSize: "14px",
                lineHeight: "1.5",
              },
            });

            editorRef.current.formatter.apply("customStyle");
          }
        }}
        apiKey="g4pmwp4e5kcjz0cum1z0vz2h0tl5vnjwc5ou58yj82sp4kbf"
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        onEditorChange={(content, editor) => {
          if (editorRef.current.getContent() === "") {
            editorRef.current.formatter.register("customStyle", {
              inline: "span",
              styles: {
                fontSize: "14px",
                lineHeight: "1.5",
              },
            });

            editorRef.current.formatter.apply("customStyle");
          }
        }}
        selector={"textarea"}
        init={{
          height: 500,
          selector: "textarea",
          menubar: ["tools", "view"],
          // language: "vi",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "video",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px",
          toolbar:
            "undo redo | blocks | media |" +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help" +
            "code" +
            "fullscreen",
          content_style:
            "body { font-family:Mulish, Helvetica, Arial, sans-serif;}" +
            `.wrong-word{
            color:red;
            cursor: pointer;
          }`,
          setup: (editor) => {
            editor.on("init", function () {
            editorRef.current.formatter.register("customStyle", {
              inline: "span",
              styles: {
                fontSize: "14px",
                lineHeight: "1.5",
              },
            });

            editorRef.current.formatter.apply("customStyle");
            });
          },
          content_css: "body { font-size: 14px; }",
          font_formats: "Mulish, Helvetica,Arial, sans-serif;", // Định nghĩa font chữ mặc định là Roboto
          formats: {
            customFormat: {
              inline: "span",
              styles: {
                "font-size": "14px",
                "font-family": "Mulish, Helvetica,Arial, sans-serif",
              },
            },
          },
          style_formats: [{ title: "14px Mulish", format: "customFormat" }],
          font_family_formats: "",
          // font_family_formats:
          //   "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Roboto=roboto; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
        }}
      />
    </div>
  );
};
export default ContentEditor;
