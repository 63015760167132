import React, { useState, useRef, useEffect } from "react";
import { Collapse, theme, Row, Col, Button, Select } from "antd";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import SelectMedia from "../../../FileManager/FileManagerMedia/SelectMedia";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import ArticleCustomEditor from "./CustomEditor";
import { updateArticleBlock } from "../../../../../store/fileManager/action";
import TextArea from "antd/es/input/TextArea";
import ToastCustom from "../../../../../Components/Common/Toast";
import { Editor } from "@tinymce/tinymce-react";

export default function UpdateBlockMagazine(props) {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const { onUpdateContent, itemkey, type, detailData } = props;
  const [defaultSetting, setDefaultSetting] = useState(false);
  const [text, setText] = useState("");
  const [number, setNumber] = useState(-1);
  const [image, setImage] = useState("");
  const [clasCss, setClassCss] = useState("Căn đều");
  const [switchItem, setSwitchItem] = useState(true);
  const [justifyContent, setJustifyContent] = useState("Căn trái");
  const [cssText, setCssText] = useState("");
  const [cssImage, setCssImage] = useState("Toàn màn hình");
  const [captionMultiImage, setCaptionMultiImage] = useState("");
  const [typeBlock, setTypeBlock] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [csswidthMultiImage, setCsswidthMultiImage] = useState("100%");
  const { eMagazineTextColor } = useSelector((state) => ({
    eMagazineTextColor: state.FileManager.eMagazineTextColor,
  }));
  const { eMagazineBlock } = useSelector((state) => ({
    eMagazineBlock: state.FileManager.eMagazineBlock,
  }));
  const { eMagazineListKey } = useSelector((state) => ({
    eMagazineListKey: state.FileManager.eMagazineListKey,
  }));
  const { eMagazineBackgroundColor } = useSelector((state) => ({
    eMagazineBackgroundColor: state.FileManager.eMagazineBackgroundColor,
  }));
  useEffect(() => {
    if (eMagazineListKey.length > 0) {
      const index = eMagazineListKey.findIndex((e) => e === itemkey);
      setNumber(index);
    }
  }, [eMagazineListKey]);
  const handleEditorChange = () => {
    setText(editorRef?.current?.getContent());
  };

  const handleNumberChange = (event) => {
    const inputNumber = event.target.value;
    if (inputNumber === "") {
      ToastCustom("Không được để trống hoặc nhập chữ", "fail");
      setCssText("90%");
      return;
    }
    if (inputNumber.includes("%")) {
      ToastCustom("Không được nhập ký tự %", "fail");
      return;
    }
    if (parseInt(inputNumber) < 0 || parseInt(inputNumber) > 100) {
      ToastCustom("Chỉ được nhập số từ 0 đến 100", "fail");
      return;
    }
    if (parseInt(inputNumber) >= 0 && parseInt(inputNumber) <= 100) {
      setCssText(inputNumber + "%");
      return;
    }
  };
  const justifyContentBlockImgText = [
    {
      label: "Sát lề hai bên",
      value: "space-between",
    },
    {
      label: "Căn hai bên phần tử",
      value: "space-around",
    },
    {
      label: "Khoảng cách đều nhau",
      value: "space-evenly",
    },
  ];

  const justifyContentBlock = [
    {
      label: "Căn trái",
      value: "flex-start",
    },
    {
      label: "Căn phải",
      value: "flex-end",
    },
    {
      label: "Căn giữa",
      value: "center",
    },
  ];
  const handleWidthBlockImage = (event) => {
    const inputNumber = event.target.value;
    if (inputNumber === "") {
      ToastCustom("Không được để trống hoặc nhập chữ", "fail");
      setCssImage("90%");
      return;
    }
    if (inputNumber.includes("%")) {
      ToastCustom("Không được nhập ký tự %", "fail");
      return;
    }
    if (parseInt(inputNumber) < 0 || parseInt(inputNumber) > 100) {
      ToastCustom("Chỉ được nhập số từ 0 đến 100", "fail");
      return;
    }
    if (parseInt(inputNumber) >= 0 && parseInt(inputNumber) <= 100) {
      setCssImage(inputNumber + "%");
      return;
    }
  };
  const handleWidthBlockMultiImage = (event) => {
    const inputNumber = event.target.value;
    if (inputNumber === "") {
      ToastCustom("Không được để trống hoặc nhập chữ", "fail");
      setCsswidthMultiImage("90%");
      return;
    }
    if (inputNumber.includes("%")) {
      ToastCustom("Không được nhập ký tự %", "fail");
      return;
    }
    if (parseInt(inputNumber) < 0 || parseInt(inputNumber) > 100) {
      ToastCustom("Chỉ được nhập số từ 0 đến 100", "fail");
      return;
    }
    if (parseInt(inputNumber) >= 0 && parseInt(inputNumber) <= 100) {
      setCsswidthMultiImage(inputNumber + "%");
      return;
    }
  };
  useEffect(() => {
    if (typeBlock === "Ảnh") {
      setText("");
      setJustifyContent("center");
      setCssImage("100%");
      setListItem([
        {
          key: uuidv4(),
          label: "Ảnh",
          children: renderItem("image"),
        },
        {
          key: uuidv4(),
          label: "Vị trí ảnh",
          children: renderItem("block"),
        },
      ]);
    }
    if (typeBlock === "Chữ") {
      setImage("");
      setCssText("100%");
      setJustifyContent("center");
      setListItem([
        {
          key: uuidv4(),
          label: "Chữ",
          children: renderItem("Chữ"),
        },
        {
          key: uuidv4(),
          label: "Vị trí chữ",
          children: renderItem("block"),
        },
      ]);
    }
    if (typeBlock === "Ảnh + Chữ") {
      setJustifyContent("space-between");
      setListItem([
        {
          key: uuidv4(),
          label: "Chữ",
          children: renderItem("Chữ"),
        },
        {
          key: uuidv4(),
          label: "Ảnh",
          children: renderItem("image"),
        },
        {
          key: uuidv4(),
          label: "Vị trí ảnh + chữ",
          children: renderItem("block"),
        },
      ]);
    }
    if (typeBlock === "Ảnh + Ảnh") {
      setText("");
      setImage("");
      setJustifyContent("space-between");
      setListItem([
        {
          key: uuidv4(),
          label: "Ảnh 1",
          children: renderItem("image1"),
        },
        {
          key: uuidv4(),
          label: "Ảnh 2",
          children: renderItem("image2"),
        },
      ]);
    }
  }, [typeBlock]);
  const TypeBlockArr = [
    {
      label: "Ảnh",
      value: "Ảnh",
    },
    {
      label: "Chữ",
      value: "Chữ",
    },
    {
      label: "Ảnh + Chữ",
      value: "Ảnh + Chữ",
    },
    {
      label: "Ảnh + Ảnh",
      value: "Ảnh + Ảnh",
    },
  ];
  const [caption, setCaption] = useState("");
  const renderItem = (
    type,
    content,
    dataClass,
    dataJusttify,
    dataCaption,
    cssText,
    cssImage,
    image1,
    image2
  ) => {
    content = content ? content : "<p><p/>";
    return (
      <div>
        {type === "image" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <label
                style={{ width: "50%", fontWeight: "700", marginTop: "8px" }}
              >
                Độ rộng khối (%)
              </label>
              <Input
                type="number"
                className="form-control"
                pattern="[0-9]{0,5}"
                onChange={handleWidthBlockImage}
                placeholder="Nhập từ 1-100"
                defaultValue={typeBlock === "Ảnh + Chữ" ? "50" : "100"}
              ></Input>
            </div>

            <SelectMedia
              type={"dropzone"}
              onUploadMedia={(e) => {
                setImage(e);
              }}
              typeArticle="e-magazine"
              defaultImgSrc={content}
              setDefaultImgSrc={() => {
                setImage("");
              }}
            ></SelectMedia>
            <Input
              style={{
                marginTop: 10,
              }}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Chú thích ảnh"
              defaultValue={dataCaption}
            ></Input>
          </div>
        )}
        {type === "image1" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SelectMedia
              type={"dropzone"}
              onUploadMedia={(e) => {
                setImage1(e);
              }}
              typeArticle="e-magazine"
              defaultImgSrc={image1}
              setDefaultImgSrc={() => {
                setImage1("");
              }}
            ></SelectMedia>
          </div>
        )}
        {type === "image2" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SelectMedia
              type={"dropzone"}
              onUploadMedia={(e) => {
                setImage2(e);
              }}
              typeArticle="e-magazine"
              defaultImgSrc={image2}
              setDefaultImgSrc={() => {
                setImage2("");
              }}
            ></SelectMedia>
          </div>
        )}
        {type === "Chữ" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <label
                style={{ width: "50%", fontWeight: "700", marginTop: "8px" }}
              >
                Độ rộng khối text (%)
              </label>
              <Input
                type="number"
                className="form-control"
                pattern="[0-9]{0,5}"
                onChange={handleNumberChange}
                placeholder="Nhập từ 1-100"
                defaultValue={cssText?.replace("%", "")}
              ></Input>
            </div>
            <Editor
              apiKey="g4pmwp4e5kcjz0cum1z0vz2h0tl5vnjwc5ou58yj82sp4kbf"
              tinymceScriptSrc={
                process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
              }
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={() => {
                handleEditorChange();
              }}
              initialValue={content}
              selector={"textarea"}
              init={{
                height: 500,
                menubar: ["tools", "view"],
                // plugins: [
                //   "advlist",
                //   "autolink",
                //   "lists",
                //   "link",
                //   "image",
                //   "charmap",
                //   "anchor",
                //   "searchreplace",
                //   "visualblocks",
                //   "code",
                //   "fullscreen",
                //   "insertdatetime",
                //   "media",
                //   "table",
                //   "preview",
                //   "help",
                //   "wordcount",
                // ],
                plugins: [
                  "mentions advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media paste code help wordcount",
                ],
                // toolbar:
                //   "undo redo | formatselect | " +
                //   "bold italic backcolor | alignleft aligncenter " +
                //   "alignright alignjustify | bullist numlist outdent indent | " +
                //   "removeformat | emoticons| help | fontsize",
                toolbar:
                  // "undo redo | blocks | " +
                  "bold italic forecolor fontsize | alignleft aligncenter" +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help" +
                  "code" +
                  "fullscreen",
                content_style:
                  "body { font-family:Mulish,Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
        )}
        {type === "block" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {typeBlock === "Ảnh + Chữ" && (
              <>
                <Select
                  placeholder="Class css"
                  options={justifyContentBlockImgText}
                  onChange={(e) => setJustifyContent((pre) => e)}
                  defaultValue={"space-between"}
                ></Select>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  onClick={() => {
                    setListItem((pre) => [pre[1], pre[0], pre[2]]);
                    setSwitchItem((pre) => !pre);
                  }}
                >
                  Đổi vị trí
                </Button>
              </>
            )}{" "}
            {typeBlock === "Ảnh" && (
              <Select
                placeholder="Class css"
                options={justifyContentBlock}
                onChange={(e) => setJustifyContent((pre) => e)}
                defaultValue={"center"}
              ></Select>
            )}
            {typeBlock === "Chữ" && (
              <Select
                placeholder="Class css"
                options={justifyContentBlock}
                onChange={(e) => setJustifyContent((pre) => e)}
                defaultValue={"center"}
              ></Select>
            )}
          </div>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (eMagazineBlock && !defaultSetting && number !== -1) {
      setClassCss(eMagazineBlock[number]?.block_text?.class_css || "");
      setText(eMagazineBlock[number]?.block_text?.content || "<p></p>");
      setCaption(eMagazineBlock[number]?.block_image?.text_image || "");
      setImage(eMagazineBlock[number]?.block_image?.content || "");
      setJustifyContent(eMagazineBlock[number]?.class_css || "");
      setCssImage(eMagazineBlock[number]?.block_image?.cssImage || "100%");
      setCssText(eMagazineBlock[number]?.block_text?.cssText || "50%");
      setImage1(eMagazineBlock[number]?.block_multi_image?.image1 || "");
      setImage2(eMagazineBlock[number]?.block_multi_image?.image2 || "");
      setCaptionMultiImage(
        eMagazineBlock[number]?.block_multi_image?.caption || ""
      );
      setTypeBlock(eMagazineBlock[number]?.type_block);
      setSwitchItem(
        eMagazineBlock[number]?.block_text?.locate === 0 ? true : false
      );
      setDefaultSetting(true);
    }
  }, [eMagazineBlock, number]);

  useEffect(() => {
    handleUpdateContent();
    const temp = eMagazineBlock.map((e, i) => {
      if (i === number) {
        return {
          block_text: {
            clas_css: e?.block_text?.class_css,
            content: text,
            locate: e?.block_text?.locate,
            cssText: cssText,
          },
          block_multi_image: {
            image1: image1,
            image2: image2,
            caption: captionMultiImage,
          },
          block_image: {
            content: image,
            text_image: caption,
            // text_image: e?.block_image?.text_image,
            locate: e?.block_image?.locate,
            cssImage: cssImage,
          },
          class_css: e?.class_css,
          color: eMagazineTextColor,
          background_color: eMagazineBackgroundColor,
          type_block: typeBlock,
        };
      } else {
        return e;
      }
    });
    dispatch(updateArticleBlock(temp));
  }, [
    eMagazineTextColor,
    eMagazineBackgroundColor,
    text,
    clasCss,
    cssImage,
    cssText,
    image,
    caption,
    justifyContent,
    switchItem,
    image1,
    image2,
    captionMultiImage,
    typeBlock,
  ]);

  // useEffect(() => {
  //   const temp = eMagazineBlock.map((e, i) => {
  //     return {
  //       ...e,
  //       color: eMagazineTextColor,
  //       background_color: eMagazineBackgroundColor,
  //     };
  //   });
  //   dispatch(updateArticleBlock(temp));
  // }, [eMagazineBackgroundColor, eMagazineTextColor]);
  const [listItem, setListItem] = useState([]);
  useEffect(() => {
    if (number !== -1 && typeBlock !== "") {
      if (typeBlock === "Ảnh + Ảnh") {
        setListItem([
          {
            key: uuidv4(),
            label: "Ảnh 1",
            forceRender: true,
            children: renderItem(
              "image1",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.block_multi_image?.image1,
              eMagazineBlock[number]?.block_multi_image?.image2,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Ảnh 2",
            forceRender: true,
            children: renderItem(
              "image2",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.block_multi_image?.image1,
              eMagazineBlock[number]?.block_multi_image?.image2,
              eMagazineBlock[number]?.type_block
            ),
          },
        ]);
      }
      if (typeBlock === "Ảnh") {
        setListItem([
          {
            key: uuidv4(),
            label: "Ảnh",
            forceRender: true,
            children: renderItem(
              "image",
              eMagazineBlock[number]?.block_image?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Khối",
            forceRender: true,
            children: renderItem(
              "block",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
        ]);
      }
      if (typeBlock === "Ảnh + Chữ") {
        setListItem([
          {
            key: uuidv4(),
            label: "Chữ",
            forceRender: true,
            children: renderItem(
              "Chữ",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Ảnh",
            forceRender: true,
            children: renderItem(
              "image",
              eMagazineBlock[number]?.block_image?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Khối",
            forceRender: true,
            children: renderItem(
              "block",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
        ]);
      }
      if (typeBlock === "Chữ") {
        setListItem([
          {
            key: uuidv4(),
            label: "Chữ",
            forceRender: true,
            children: renderItem(
              "Chữ",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Khối",
            forceRender: true,
            children: renderItem(
              "block",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
        ]);
      }
      if (!typeBlock) {
        setListItem([
          {
            key: uuidv4(),
            label: "Chữ",
            forceRender: true,
            children: renderItem(
              "Chữ",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Ảnh",
            forceRender: true,
            children: renderItem(
              "image",
              eMagazineBlock[number]?.block_image?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Ảnh 1",
            forceRender: true,
            children: renderItem(
              "image1",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.block_multi_image?.image1,
              eMagazineBlock[number]?.block_multi_image?.image2,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Ảnh 2",
            forceRender: true,
            children: renderItem(
              "image2",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.block_multi_image?.image1,
              eMagazineBlock[number]?.block_multi_image?.image2,
              eMagazineBlock[number]?.type_block
            ),
          },
          {
            key: uuidv4(),
            label: "Khối",
            forceRender: true,
            children: renderItem(
              "block",
              eMagazineBlock[number]?.block_text?.content,
              eMagazineBlock[number]?.block_text?.class_css,
              eMagazineBlock[number]?.class_css,
              eMagazineBlock[number]?.block_image?.text_image,
              eMagazineBlock[number]?.block_text?.cssText,
              eMagazineBlock[number]?.block_image?.cssImage,
              eMagazineBlock[number]?.type_block
            ),
          },
        ]);
      }
    }
  }, [number, typeBlock]);
  const handleUpdateContent = () => {
    onUpdateContent(
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0px 30px",
          justifyContent: `${justifyContent}`,
          backgroundColor: eMagazineBackgroundColor,
        }}
      >
        {switchItem ? (
          <>
            {text && (
              <div
                style={{
                  alignSelf: clasCss,
                  color: eMagazineTextColor,
                  marginBottom: "20px",
                  width: cssText,
                  wordWrap: "break-word",
                }}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            )}
            {image && (
              <figure
                className="image"
                id="add_image_link"
                style={{ width: cssImage, marginBottom: "20px" }}
              >
                <img width="100%" src={image} alt="" />
                <figcaption style={{ textAlign: "center" }}>
                  {caption}
                </figcaption>
              </figure>
            )}
            {image1 && image2 && (
              <div className="multyImage">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: csswidthMultiImage,
                    margin: "0 auto",
                  }}
                >
                  {image1 && (
                    <figure
                      className="image"
                      id="add_image_link"
                      style={{ width: "49.9%", marginBottom: "20px" }}
                    >
                      <img width="100%" src={image1} alt="" />
                    </figure>
                  )}
                  {image2 && (
                    <figure
                      className="image"
                      id="add_image_link"
                      style={{ width: "49.9%", marginBottom: "20px" }}
                    >
                      <img width="100%" src={image2} alt="" />
                    </figure>
                  )}
                </div>
                {captionMultiImage && (
                  <figcaption
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      marginTop: "-14px",
                      marginBottom: "20px",
                    }}
                  >
                    {captionMultiImage}
                  </figcaption>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {image && (
              <figure
                className="image"
                id="add_image_link"
                style={{ width: cssImage, marginBottom: "20px" }}
              >
                <img width="100%" src={image} alt="" />
                <figcaption style={{ textAlign: "center" }}>
                  {caption}
                </figcaption>
              </figure>
            )}
            {text && (
              <div
                style={{
                  alignSelf: clasCss,
                  color: eMagazineTextColor,
                  marginBottom: "20px",
                  width: cssText,
                  wordWrap: "break-word",
                }}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            )}
            {image1 && (
              <figure
                className="image"
                id="add_image_link"
                style={{ width: "50%", marginBottom: "20px" }}
              >
                <img width="100%" src={image1} alt="" />
              </figure>
            )}
            {image2 && (
              <figure
                className="image"
                id="add_image_link"
                style={{ width: "50%", marginBottom: "20px" }}
              >
                <img width="100%" src={image2} alt="" />
              </figure>
            )}
          </>
        )}
      </div>,
      number,
      ArticleCustomEditor(
        eMagazineTextColor,
        eMagazineBackgroundColor,
        text,
        clasCss,
        cssImage,
        cssText,
        image,
        caption,
        justifyContent,
        switchItem,
        image1,
        image2,
        captionMultiImage,
        csswidthMultiImage,
        typeBlock
      )
    );
  };
  return (
    <>
      {typeBlock !== "" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <label style={{ width: "20%", fontWeight: "700", marginTop: "8px" }}>
            Loại Khối :
          </label>
          <Select
            style={{ width: "80%", marginBottom: "15px", marginLeft: "10px" }}
            placeholder="Class css"
            options={TypeBlockArr}
            onChange={(e) => setTypeBlock((pre) => e)}
            defaultValue={typeBlock}
          ></Select>
        </div>
      )}

      {typeBlock === "Ảnh + Ảnh" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <label style={{ width: "50%", fontWeight: "700", marginTop: "8px" }}>
            Độ rộng khối (%)
          </label>
          <Input
            type="number"
            className="form-control"
            pattern="[0-9]{0,5}"
            onChange={handleWidthBlockMultiImage}
            placeholder="Nhập từ 1-100"
            defaultValue="100"
          ></Input>
        </div>
      )}

      <Collapse accordion items={listItem} />
      {typeBlock === "Ảnh + Ảnh" && (
        <Input
          style={{
            marginTop: 10,
          }}
          onChange={(e) => setCaptionMultiImage(e.target.value)}
          defaultValue={captionMultiImage}
          placeholder="Chú thích ảnh"
        ></Input>
      )}
    </>
  );
}
